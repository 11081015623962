import cn from 'classnames';
import { capitalize } from '@src/utils/stringUtils';
import type { WalletCardProps } from '@src/components/cards/walletCard';

export default function WalletCardSecondary({
  name,
  logo,
  selected,
  ...props
}: WalletCardProps) {
  return (
    <button
      {...props}
      className={cn(
        selected
          ? 'bg-gray-200 dark:bg-primary-700'
          : 'bg-gray-50 dark:bg-dark-neutral-800',
        'flex min-w-[165px] items-center gap-3 rounded-lg border border-white border-opacity-5 p-3 hover:bg-gray-200 disabled:cursor-not-allowed disabled:bg-dark-neutral-700 disabled:hover:bg-gray-50 dark:border-dark-neutral-700 dark:text-white hover:dark:bg-primary-600 disabled:hover:dark:bg-dark-neutral-800'
      )}
    >
      <img src={logo} className="h-6 w-6" alt={`${name} Icon`} />
      <span className="body14 font-medium">{capitalize(name)}</span>
    </button>
  );
}

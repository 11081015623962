import { toast } from 'react-toastify';
import type { ToastContentProps } from '@src/components/atoms/toast/toastContent';
import ToastContent from '@src/components/atoms/toast/toastContent';

const showToast = ({
  type,
  heading,
  description,
  toastId,
}: ToastContentProps) => {
  toast(
    <ToastContent type={type} heading={heading} description={description} />,
    {
      type,
      toastId,
    }
  );
};

export default showToast;

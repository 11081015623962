export const searchLabel = {
  meta: {
    dataHash: 'Data Hash',
    fetchedUrl: 'Fetched URL',
    fetchedAt: 'Fetched At',
    drepIdHex: 'DRep ID (Hex)',
    drepIdCip105: 'DRep ID (Cip105)',
    drepIdCip129: 'DRep ID (Cip129)',
  },
  givenName: 'Given Name',
  motivations: 'Motivations',
  objectives: 'Objectives',
  bio: 'Bio',
  email: 'Email',
  dRepName: 'DRep Name',
  qualifications: 'Qualifications',
  paymentAddress: 'Payment Address',
  references: {
    type: 'References Type',
    label: 'References Label',
    uri: 'References URI',
  },
};

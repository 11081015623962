import type { TypeOptions } from 'react-toastify';
import cn from 'classnames';
import { getToastIcon } from '@src/components/atoms/toast/toastContainer';

type ContentType = 'heading' | 'description' | 'parent';

export const TOASTSTYLES: Record<TypeOptions, Record<ContentType, string>> = {
  error: {
    heading: 'text-semantic-error-700',
    description: 'text-semantic-error-600',
    parent: 'border-semantic-error-600 bg-semantic-error-50',
  },
  warning: {
    heading: 'text-semantic-warning-700',
    description: 'text-semantic-error-600',
    parent: 'border-semantic-warning-600 bg-semantic-warning-50',
  },
  success: {
    heading: 'text-semantic-success-700',
    description: 'text-semantic-error-600',
    parent: 'border-semantic-success-600 bg-semantic-success-50',
  },
  info: {
    heading: 'text-semantic-information-700',
    description: 'text-semantic-information-600',
    parent: 'border-semantic-information-600 bg-semantic-information-50',
  },
  default: {
    heading: 'text-gray-700',
    description: 'text-gray-600',
    parent: 'border-gray-200 bg-gray-50',
  },
};

export interface ToastContentProps {
  type?: TypeOptions;
  heading?: string;
  isStorybook?: boolean;
  description?: string;
  toastId?: string;
}

export default function ToastContent({
  type = 'info',
  heading,
  isStorybook = false,
  description,
}: ToastContentProps) {
  return (
    <div
      className={cn(
        'body14 flex max-w-[500px] items-start gap-3 dark:bg-dark-neutral-700',
        TOASTSTYLES[type].parent,
        isStorybook && 'rounded-xl border-t-[3px] p-4'
      )}
    >
      {isStorybook && getToastIcon(type)}
      <div className="flex flex-col gap-2">
        {heading && (
          <p className={cn(TOASTSTYLES[type].heading, 'font-semibold')}>
            {heading}
          </p>
        )}
        {description && (
          <div className={cn(TOASTSTYLES[type].description, 'dark:text-white')}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
}

import AvatarIcon from '@src/components/icons/avatarIcon';
import DeleteIcon from '@src/components/icons/delete';
import { getImageUrlString } from '@src/utils/dRepUtils';
import cn from 'classnames';

interface AvatarProps {
  src?: string;
  size?: number;
  className?: string;
  handleDeleteImage?: (event: React.MouseEvent) => void;
}

export default function Avatar({
  src,
  size = 24,
  className,
  handleDeleteImage,
}: AvatarProps) {
  return (
    <div
      style={{ width: size, height: size }}
      className={cn(
        'relative flex h-[218px] items-center justify-center bg-gray-100 dark:bg-white',
        getImageUrlString(src)
          ? 'rounded-full'
          : 'rounded-lg border border-gray-200 p-1 dark:border-none',
        className
      )}
    >
      {getImageUrlString(src) ? (
        <>
          <img
            src={getImageUrlString(src) as string}
            alt="Profile"
            className="h-full w-full rounded-full object-cover"
          />
          {handleDeleteImage && (
            <div
              onClick={handleDeleteImage}
              className="absolute -right-4 bottom-0 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-4 bg-gray-200 dark:border-dark-neutral-900 dark:bg-white"
            >
              <DeleteIcon className="h-4 w-4 text-semantic-error-700" />
            </div>
          )}
        </>
      ) : (
        <AvatarIcon className="h-full w-full" />
      )}
    </div>
  );
}

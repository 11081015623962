import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IError } from '@src/models/dtos';

export interface FormState {
  identityLinksError?: IError;
  socialLinksError?: IError;
  paymentAddressError?: string;
}

const initialState: FormState = {};

export const dRepFormErrorSlice = createSlice({
  name: 'dRepformErrorState',
  initialState,
  reducers: {
    setIdentityLinksError: (state, action: PayloadAction<IError>) => {
      state.identityLinksError = action.payload;
    },
    setSocialLinksError: (state, action: PayloadAction<IError>) => {
      state.socialLinksError = action.payload;
    },
    setPaymentAddressError: (state, action: PayloadAction<string>) => {
      state.paymentAddressError = action.payload;
    },
  },
});

export const {
  setIdentityLinksError,
  setSocialLinksError,
  setPaymentAddressError,
} = dRepFormErrorSlice.actions;

export default dRepFormErrorSlice.reducer;

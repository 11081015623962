import environments from '@src/configs/environments';
import type { IAnchor } from '@src/models/dtos/metadata';
import type { CIP30Wallet } from 'kuber-client';
import { Kuber } from 'kuber-client';
import type { TxResponseModal } from 'kuber-client/types';

const kuber = new Kuber(
  (environments.BASE_API_URL?.replace('/api', '') || '/') as string
);

export async function stakeDelegation(
  wallet: CIP30Wallet,
  stakeAddress: string,
  drepId: string
): Promise<TxResponseModal> {
  let certificates: any = [
    {
      type: 'delegate',
      key: stakeAddress,
      drep: drepId,
    },
  ];
  const registeredStakeKeysListState =
    await wallet.cip95?.getRegisteredPubStakeKeys();
  console.log(registeredStakeKeysListState);
  if (registeredStakeKeysListState?.length === 0) {
    const stakeRegisterCertificate = {
      key: stakeAddress,
      type: 'registerstake',
    };
    certificates = [stakeRegisterCertificate, ...certificates];
  }
  return await kuber.buildWithProvider(
    wallet,
    {
      certificates,
    },
    false,
    50_000_000
  );
}

export async function dRepRegistration(
  wallet: CIP30Wallet,
  dRepAddress: string,
  anchor: IAnchor
): Promise<TxResponseModal> {
  const certificates: any = [
    {
      type: 'registerdrep',
      key: dRepAddress,
      anchor: anchor,
    },
  ];

  return await kuber.buildWithProvider(
    wallet,
    {
      certificates,
    },
    false,
    600_000_000
  );
}

export async function updateDRep(
  wallet: CIP30Wallet,
  dRepAddress: string,
  anchor: IAnchor
): Promise<TxResponseModal> {
  const certificates: any = [
    {
      type: 'updateDRep',
      key: dRepAddress,
      anchor: anchor,
    },
  ];
  return await kuber.buildWithProvider(
    wallet,
    {
      certificates,
    },
    false,
    50_000_000
  );
}

export async function dRepDeRegistration(
  wallet: CIP30Wallet,
  dRepAddress: string
): Promise<TxResponseModal> {
  const utxo = (await wallet.instance.getUtxos())[0] || '';
  const certificates: any = [
    {
      type: 'deregisterdrep',
      key: dRepAddress,
    },
  ];
  return await kuber.buildWithProvider(
    wallet,
    {
      certificates,
      inputs: utxo,
    },
    false,
    50_000_000
  );
}

export async function vote({
  wallet,
  voteType,
  proposal,
  voter,
  anchor,
}: {
  wallet: CIP30Wallet;
  voteType: string;
  proposal: string;
  voter: string;
  anchor?: IAnchor;
}): Promise<TxResponseModal> {
  const vote: any = [
    {
      voter: voter,
      role: 'drep',
      proposal: proposal,
      vote: voteType,
      ...(anchor && { anchor: anchor }),
    },
  ];

  console.log(vote, 'vote');
  return await kuber.buildWithProvider(
    wallet,
    {
      vote,
    },
    false,
    50_000_000
  );
}

import type { SearchDRepMetadataResponse } from '@src/store/search/types';
import AvatarIcon from '@src/components/icons/avatarIcon';
import { getValueByPath } from '@src/utils/metadataUtils';
import { searchLabel } from '@src/constants/searchLabel';
import type { TokenType } from '@src/store/drep/types';
import Badge from '@src/components/atoms/badges/badge';
import CustomizeToolTip from '@src/components/atoms/tooltip';
import { capitalize } from '@src/utils/stringUtils';
import type { DRepStatus } from '@src/models/types/dRep';

interface SearchCardProps {
  item: SearchDRepMetadataResponse;
  token?: TokenType | null;
}

export default function SearchCard({ item, token }: SearchCardProps) {
  const getBadgeColor = (status?: DRepStatus) => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Inactive':
        return 'warning';
      default:
        return 'error';
    }
  };
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-3 dark:bg-dark-neutral-800">
      <div className="flex items-center justify-between gap-2">
        <div className="label12 flex items-center gap-2 font-medium">
          {item.image ? (
            <img
              src={item.image}
              alt="Previous DRep"
              className="h-6 w-6 rounded-full"
            />
          ) : (
            <AvatarIcon className="h-6 w-6" />
          )}
          <p className="dark:text-white">{item.givenName}</p>
          {token && (
            <>
              <div className="w-[1px] dark:bg-white"></div>
              <p className="dark:text-gray-400">{token.name}</p>
            </>
          )}
        </div>
        {item.status && (
          <Badge
            label={capitalize(item.status)}
            color={getBadgeColor(item.status)}
          />
        )}
      </div>
      <hr className="h-[1px] w-full"></hr>
      <div className="flex flex-col gap-1">
        <p className="label12 flex gap-1 truncate">
          <span className="min-w-[140px] font-medium dark:text-white">
            Given Name:
          </span>
          <span className="flex-1 dark:text-gray-400">{item.givenName}</span>
        </p>
        <p className="label12 flex gap-1 truncate">
          <span className="min-w-[140px] font-medium dark:text-white">
            DRep ID (CIP 105):
          </span>
          <span className="flex-1 dark:text-gray-400">
            {item.meta.drepIdCip105}
          </span>
        </p>
        {Object.keys(item.highlight).map((key, index) => (
          <p key={key} className="label12 flex gap-1">
            <span className="min-w-[140px] font-medium text-neutral-600 dark:text-white">
              {getValueByPath(searchLabel, key)}:
            </span>
            <span
              data-tooltip-id={item.givenName + index}
              data-tooltip-place="bottom-start"
              className="search-value flex-1 truncate break-words dark:text-gray-400"
              dangerouslySetInnerHTML={{
                //@ts-ignore
                __html: item.highlight[key],
              }}
            ></span>
            {
              //@ts-ignore`
              item.highlight[key].toString().length > 64 && (
                <CustomizeToolTip
                  className="search-value"
                  id={item.givenName + index}
                  description={
                    //@ts-ignore
                    item.highlight[key].toString()
                  }
                />
              )
            }
          </p>
        ))}
      </div>
    </div>
  );
}

import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

interface SocialShareWrapperProps {
  children: React.ReactNode;
  label: string;
}

const shareButtonMap: Record<
  string,
  React.ComponentType<{ url: string; children: React.ReactNode }>
> = {
  facebook: FacebookShareButton,
  'x/twitter': TwitterShareButton,
  linkedin: LinkedinShareButton,
  whatsapp: WhatsappShareButton,
  telegram: TelegramShareButton,
  email: EmailShareButton,
};

export default function SocialShareWrapper({
  children,
  label,
}: SocialShareWrapperProps) {
  const ShareButton = shareButtonMap[label.toLowerCase()];

  return ShareButton ? (
    <ShareButton url={window.location.href}>
      <div>{children}</div>
    </ShareButton>
  ) : (
    <>{children}</>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environments from '@src/configs/environments';
import type { SearchDRepMetadataResponse } from './types';

export const SEARCH_PATH = 'search';

export const searchApi = createApi({
  reducerPath: SEARCH_PATH,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.append('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    search: builder.query<Array<SearchDRepMetadataResponse>, string>({
      query: (query) => ({
        url: `/search?query=${query}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazySearchQuery } = searchApi;

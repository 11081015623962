import type { InputHTMLAttributes } from 'react';
import React, { useRef, forwardRef } from 'react';
import cn from 'classnames';
import ErrorIcon from '@src/components/icons/error';
import WarningIcon from '@src/components/icons/warning';
import SuccessIcon from '@src/components/icons/success';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputfieldSize?: size;
  variant?: variant;
  preActions?: React.ReactNode[];
  postActions?: React.ReactNode[];
  isStatusIconVisible?: boolean;
  helperText?: string;
  renderSeperators?: boolean;
  isRequired?: boolean;
}

type size = 'small' | 'medium' | 'large';
type variant = 'error' | 'warning' | 'success' | 'neutral';

const INPUTFIELDSIZES: Record<size, string> = {
  small: 'h-11 px-3 py-[10px]',
  medium: 'h-12 p-3',
  large: 'h-[52px] px-3 py-[14px]',
};

const INPUTFIELDVARIANTS: Record<variant, string> = {
  error: 'border-semantic-error-500 dark:border-semantic-error-500',
  warning: 'border-semantic-warning-500 dark:border-semantic-warning-500',
  success: 'border-semantic-success-500 dark:border-semantic-success-500',
  neutral:
    'border-gray-200 dark:border-dark-neutral-500 dark:bg-dark-neutral-600',
};

const FOCUS_CLASSES: Record<variant, Array<string>> = {
  error: ['shadow-Error-Shadow-1'],
  warning: ['shadow-Warning-Shadow-1'],
  success: ['shadow-Success-Shadow-1'],
  neutral: [
    'shadow-Default-Shadow-1',
    'border-brand',
    'dark:border-white',
    'dark:shadow-White-Shadow-1',
  ],
};

const HELPERTEXTCLASS: Record<variant, string> = {
  error: 'text-semantic-error-500',
  warning: 'text-semantic-warning-500',
  success: 'text-semantic-success-500',
  neutral: 'text-gray-500',
};

const getStatusIcon = (variant: variant) => {
  switch (variant) {
    case 'error':
      return <ErrorIcon className="text-semantic-error-500" />;
    case 'warning':
      return <WarningIcon className="text-semantic-warning-500" />;
    case 'success':
      return <SuccessIcon className="text-semantic-success-500" />;
    default:
      return null;
  }
};

export default forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      className,
      variant = 'neutral',
      inputfieldSize = 'medium',
      preActions,
      postActions,
      isStatusIconVisible = false,
      helperText,
      renderSeperators = true,
      isRequired = false,
      ...props
    }: InputFieldProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const inputDivRef = useRef<HTMLDivElement>(null);

    const toggleFocusClasses = (isFocused: boolean) => {
      if (inputDivRef.current) {
        const classes = FOCUS_CLASSES[variant];
        if (isFocused) {
          inputDivRef.current.classList.add(...classes);
          if (variant === 'neutral') {
            inputDivRef.current.classList.remove(
              'border-gray-200',
              'dark:border-dark-neutral-500'
            );
          }
        } else {
          inputDivRef.current.classList.remove(...classes);
          if (variant === 'neutral') {
            inputDivRef.current.classList.add(
              'border-gray-200',
              'dark:border-dark-neutral-500'
            );
          }
        }
      }
    };

    const renderActions = (actions?: React.ReactNode[], separator = false) =>
      actions?.map((action, index) => (
        <React.Fragment key={index}>
          <div>{action}</div>
          {separator && (
            <div className="h-full w-[1px] rounded-[30px] bg-gray-200"></div>
          )}
        </React.Fragment>
      ));

    return (
      <label className="flex w-full flex-col">
        {label && (
          <span className="body14 mb-3 font-medium text-gray-900 dark:text-white">
            {label}
            {isRequired && <span className="text-semantic-error-600"> *</span>}
          </span>
        )}
        <div
          ref={inputDivRef}
          className={cn(
            'body16 flex w-full items-center gap-2 rounded-[12px] border bg-white font-medium dark:border-dark-neutral-50 dark:bg-dark-neutral-600',
            className,
            INPUTFIELDVARIANTS[variant],
            INPUTFIELDSIZES[inputfieldSize]
          )}
          onFocus={() => toggleFocusClasses(true)}
          onBlur={() => toggleFocusClasses(false)}
        >
          {renderActions(preActions, renderSeperators)}
          <input
            ref={ref}
            {...props}
            className={cn(
              'w-full bg-white text-gray-900 placeholder:text-gray-600 focus:outline-0 dark:bg-dark-neutral-600 dark:text-white dark:placeholder:text-gray-400'
            )}
          />
          {isStatusIconVisible && getStatusIcon(variant)}
          {postActions?.map((action, index) => <div key={index}>{action}</div>)}
        </div>
        {helperText && (
          <span
            className={cn('body14 mt-3 font-medium', HELPERTEXTCLASS[variant])}
          >
            {helperText}
          </span>
        )}
      </label>
    );
  }
);

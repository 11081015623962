import Avatar from '@src/components/atoms/avatar';
import cn from 'classnames';
import { useRouter } from 'next/navigation';

interface ProfileCardProps {
  givenName?: string;
  profileImage?: string;
  tokenName: string;
  isActiveProfile?: boolean;
  disabled?: boolean;
}
export default function ProfileCard({
  givenName,
  profileImage,
  tokenName,
  isActiveProfile = false,
  disabled = false,
}: ProfileCardProps) {
  const router = useRouter();
  return (
    <div
      onClick={() => !disabled && router.push(`/${tokenName}`)}
      className={cn(
        'relative rounded-xl bg-dark-neutral-800 bg-opacity-5 p-2 hover:bg-opacity-10 dark:bg-white dark:bg-opacity-5 dark:hover:bg-opacity-10',
        !disabled && 'cursor-pointer'
      )}
    >
      <div className="flex items-center gap-2">
        <div>
          <Avatar src={profileImage} size={32} />
        </div>
        <div className="body14 flex flex-col pr-2 font-medium">
          <p className="line-clamp-1 dark:text-white">
            {givenName ?? 'DATA MISSING'}
          </p>
          <p className="line-clamp-1 dark:text-gray-400">@{tokenName}</p>
        </div>
      </div>
      <div
        className={cn(
          'absolute right-2 top-2 flex h-[10px] w-[10px] items-center justify-center rounded-full',
          isActiveProfile ? 'bg-brand' : 'border border-gray-400'
        )}
      >
        {isActiveProfile && (
          <div className="h-1 w-1 rounded-full bg-gray-400"></div>
        )}
      </div>
    </div>
  );
}

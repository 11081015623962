import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { isEmptyString } from '@src/utils/stringUtils';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import InputField from '@src/components/atoms/textfield/inputField';
import SearchIcon from '@src/components/icons/search';
import EmptySearchCard from '@src/components/cards/emptySearchCard';
import { useModal } from '@src/components/modal-views/context';

type AutocompleteSearchProps<T> = {
  searchQuery: (term: string) => Promise<T[]>;
  placeholder?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  onItemClick: (item: T) => void;
  debounceDelay?: number;
};

const AutocompleteSearch = <T,>({
  searchQuery,
  placeholder = 'Search...',
  renderItem,
  onItemClick,
  debounceDelay = 500,
}: AutocompleteSearchProps<T>) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<T[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { closeModal } = useModal();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (!isEmptyString(value)) {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
      try {
        const results = await searchQuery(value);
        setData(results);
        setIsOpen(true);
      } catch (e) {
        toast.error('Failed to fetch search results', {
          toastId: ToastId.ERROR_TOAST,
        });
      }
    } else {
      setData([]);
    }
  };

  const debouncedSearch = useMemo(
    () => debounce(handleInputChange, debounceDelay),
    [debounceDelay]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        setIsOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-40 backdrop-blur-[3px] dark:bg-[#07090C03]"></div>
      )}
      <div
        ref={ref}
        className={cn(
          'relative',
          isOpen &&
            'z-50 rounded-t-[18px] bg-white dark:bg-dark-neutral-900 dark:bg-opacity-80'
        )}
      >
        <div
          className={cn(
            'p-2',
            isOpen &&
              'rounded-t-[18px] border border-b-0 dark:border-dark-neutral-200'
          )}
        >
          <InputField
            inputfieldSize="small"
            placeholder={placeholder}
            preActions={[
              <SearchIcon
                key="searchIcon"
                className="text-dark-neutral-900 dark:text-white"
              />,
            ]}
            postActions={[
              <p
                key="shortcut"
                className="body14 font-semibold dark:text-white"
              >
                CTRL+S
              </p>,
            ]}
            variant={
              isEmptyString(searchTerm)
                ? 'neutral'
                : data.length != 0
                  ? 'neutral'
                  : 'error'
            }
            onChange={debouncedSearch}
            className="w-full xl:min-w-[500px]"
          />
        </div>
        {isOpen && (
          <div
            ref={containerRef}
            className={cn(
              'absolute top-[60px] flex max-h-[500px] w-full flex-col gap-2 overflow-scroll rounded-b-[18px] border border-t-0 bg-white px-3 py-2 dark:border-dark-neutral-200 dark:bg-dark-neutral-900 dark:bg-opacity-80',
              data.length === 0 && '!pb-0'
            )}
          >
            {data.length > 0 && (
              <>
                <div className="flex items-center gap-2 dark:text-white">
                  <span className="body14 font-semibold">
                    Best matched DRep IDs
                  </span>
                  <span className="label12">{data.length + 1}+ results</span>
                </div>
                {data.map((item, index) => (
                  <div
                    onClick={() => {
                      closeModal();
                      onItemClick(item);
                      setIsOpen(false);
                    }}
                    className={cn('flex cursor-pointer flex-col')}
                    key={index}
                  >
                    {renderItem(item, index)}
                  </div>
                ))}
              </>
            )}

            {data.length === 0 && !isEmptyString(searchTerm) && (
              <>
                <p className="label12 dark:text-white">0 results</p>
                <EmptySearchCard />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AutocompleteSearch;

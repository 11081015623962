import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface IMintSuccessfullState {
  value: string | null;
}

const initialState: IMintSuccessfullState = {
  value: null,
};

export const mintSuccessfullSlice = createSlice({
  name: 'mintSuccessfull',
  initialState,
  reducers: {
    setMintSuccessfull: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    resetMintSuccessfull: (state) => {
      state.value = null;
    },
  },
});

export const { setMintSuccessfull, resetMintSuccessfull } =
  mintSuccessfullSlice.actions;
export default mintSuccessfullSlice.reducer;

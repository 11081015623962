import { useModal } from '../context';
import { Button } from '@headlessui/react';
import DisconnectWalletIcon from '@src/components/icons/walletIcon';
import DialogIconWrapper from '@src/components/atoms/dialogIconWrapper';

interface IDisconnectWalletModal {
  handleDisconnectWallet: () => void;
}
export default function DisconnectWalletModal({
  handleDisconnectWallet,
}: IDisconnectWalletModal) {
  const { closeModal } = useModal();
  return (
    <div className="mx-2 flex rounded-3xl border p-2 dark:border-dark-neutral-200 md:min-w-[466px]">
      <div className="relative flex w-full flex-col items-center gap-5 rounded-[18px] bg-white px-5 py-8 shadow-modal-shadow dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <div className="absolute left-0 top-0 h-[107px] w-full bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <DialogIconWrapper>
          <DisconnectWalletIcon className="h-6 w-6" />
        </DialogIconWrapper>
        <div className="flex flex-col gap-3 text-center text-neutral-900 dark:text-white">
          <h3 className="h24 font-bold">Disconnect Wallet?</h3>
          <p className="body16 w-full max-w-[326px]">
            Are you sure you want to disconnect the wallet? You can always
            connect again.
          </p>
        </div>
        <div className="h-[1px] w-full bg-section-gradent"></div>
        <div className="body16 flex items-center justify-center gap-3 font-medium">
          <Button
            onClick={closeModal}
            className="h-[40px] rounded-xl bg-semantic-success-600 px-3 text-white dark:border-gray-200 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDisconnectWallet}
            className="h-[40px] rounded-xl bg-semantic-error-600 px-3 text-white dark:bg-semantic-error-500 dark:text-white dark:hover:bg-semantic-error-700"
          >
            Disconnect
          </Button>
        </div>
      </div>
    </div>
  );
}

'use client';

import React from 'react';
import cn from 'classnames';
import Navbar from '@src/layouts/navbar';
import Footer from '@src/layouts/footer';
import { ScrollProvider } from '@src/context/scroll-context';
import { usePathname } from 'next/navigation';
import { Provider } from 'react-redux';
import { store } from '@src/store/store';
import ModalContainer from '@src/components/modal-views/container';

export const eventEmitter = new EventTarget();

interface ILayoutProps {
  className?: string;
}

export default function Layout({
  children,
  className,
}: React.PropsWithChildren<ILayoutProps>) {
  const pathname = usePathname();

  return (
    <Provider store={store}>
      <ScrollProvider>
        <div className="z-20 flex min-h-screen w-full flex-col items-center justify-center bg-white dark:bg-dark-neutral-950">
          <Navbar />
          <div className="h-[0.5px] w-full max-w-[1440px] bg-layout-divider px-4 sm:px-5 md:px-[72px]"></div>
          <div className="absolute -top-[150px] hidden h-[428px] w-full bg-Radial-gradient sm:flex"></div>
          <main
            className={cn(
              `flex h-full min-h-calc-548 w-full flex-col items-center justify-center`,
              className
            )}
          >
            {children}
          </main>
          {pathname === '/' && <Footer />}
        </div>
      </ScrollProvider>
      <ModalContainer />
    </Provider>
  );
}

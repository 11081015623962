import type { Reducer } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer } from 'redux-persist';
import { RESET_STATE_ACTION_TYPE } from '@src/store/actions/resetState';
import { drepApi } from '@src/store/drep/api';
import { metadataApi } from '@src/store/metadata/api';
import { transactionApi } from '@src/store/transaction/api';
import persistedPendingtransactionReducer from '@src/store/transaction/transaction';
import storage from 'redux-persist/lib/storage';
import { walletSlice } from '@src/store/user/wallet';
import { dRepFormErrorSlice } from '@src/store/drep/dRepRegistrationFormErrorSlice';
import { searchApi } from '@src/store/search/api';
import { configApi } from '@src/store/config/api';
import { mintSuccessfullSlice } from './drep/mintSuccessfullSlice';
import { governanceActionApi } from '@src/store/governanceAction/api';
import { configSlice } from '@src/store/config/slice';

const middlewares = [
  drepApi.middleware,
  metadataApi.middleware,
  transactionApi.middleware,
  searchApi.middleware,
  configApi.middleware,
  governanceActionApi.middleware,
];

const walletPersistConfig = {
  key: 'wallet',
  storage,
  whitelist: ['name', 'icon', 'instance', 'network', 'walletInfo'],
};

const persistedWalletReducer = persistReducer(
  walletPersistConfig,
  walletSlice.reducer
);

const dRepFormPersistConfig = {
  key: 'dRepFormError',
  storage,
  whitelist: [],
};

const dRepFormErrorReducer = persistReducer(
  dRepFormPersistConfig,
  dRepFormErrorSlice.reducer
);

const mintSuccessfullConfig = {
  key: 'mintSuccessfull',
  storage,
  whitelist: ['value'],
};

const persistedMintSuccessfullReducer = persistReducer(
  mintSuccessfullConfig,
  mintSuccessfullSlice.reducer
);

const configPersistConfig = {
  key: 'config',
  storage,
  whitelist: ['fee', 'protocolParams'],
};

const configReducer = persistReducer(configPersistConfig, configSlice.reducer);

const reducers = {
  [drepApi.reducerPath]: drepApi.reducer,
  wallet: persistedWalletReducer,
  [metadataApi.reducerPath]: metadataApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  pendingTransactions: persistedPendingtransactionReducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  dRepFormError: dRepFormErrorReducer,
  mintSuccessfull: persistedMintSuccessfullReducer,
  config: configReducer,
  [governanceActionApi.reducerPath]: governanceActionApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

setupListeners(store.dispatch);

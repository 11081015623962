import DRepSearch from '@src/components/molecules/dRepSearch';
import { useEffect, useRef } from 'react';
import { useModal } from '@src/components/modal-views/context';

export default function SearchModal() {
  const { closeModal } = useModal();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="min-h-screen w-full px-5 py-4">
      <div ref={ref}>
        <DRepSearch />
      </div>
    </div>
  );
}

'use client';

import React, { useState, useEffect } from 'react';
import AnchorLink from '@src/components/atoms/links/anchor-link';
import Burger from '@src/components/icons/burger';
import { useScrollContext } from '@src/context/scroll-context';
import { usePathname } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@src/store/store';
import { removeWallet } from '@src/store/user/wallet';
import { useDRepLogoutMutation } from '@src/store/drep/api';
import { useLazyGetDRepByIdQuery } from '@src/store/drep/api';
import { getCip30Wallet } from '@src/utils/kuberUtils';
import {
  getDRepIdFromCip30Wallet,
  getImageUrlString,
  getMetadataResponse,
} from '@src/utils/dRepUtils';
import type { DRepMetadata } from '@src/store/drep/types';
import DRepSearch from '@src/components/molecules/dRepSearch';
import withWalletEventListener from '@src/walletEvent/walletStateUtils';
import ProfileDropDown from '@src/components/atoms/dropdown/profileDropdown';
import LogoComponent from '@src/components/atoms/logoComponent';
import type { IMetadata } from '@src/models/dtos/metadata';
import { getFieldValue } from '@src/utils/metadataUtils';
import Button from '@src/components/atoms/buttons/button';
import SearchIcon from '@src/components/icons/search';
import { useModal } from '@src/components/modal-views/context';

function Navbar() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [dRepLogout] = useDRepLogoutMutation();
  const { scrollToSection, howItWorksRef, featuresRef } = useScrollContext();
  const [getDRepInformationById] = useLazyGetDRepByIdQuery();
  const [isDRepInformationFetching, setDRepInformationFetching] =
    useState(false);
  const [tokenList, setTokenList] = useState<Array<string>>([]);
  const path = usePathname();
  const [metadata, setMetadata] = useState<IMetadata | null>(null);
  const { openModal } = useModal();

  const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
    scrollToSection(ref);
    if (isMenuVisible) {
      setMenuVisible(false);
    }
  };

  const [isMounted, setIsMounted] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);

  useEffect(() => {
    // This ensures that rendering only happens after the component is mounted on the client.
    // Prevent Hydration error
    setIsMounted(true);
    getTokenList();
  }, [wallet.instance, isMounted]);

  const dispatch = useDispatch();

  const getTokenList = async () => {
    setTokenList([]);
    if (isMounted && wallet.instance !== null) {
      setDRepInformationFetching(true);
      const walletFromKuber = await getCip30Wallet(wallet);
      if (!walletFromKuber) {
        console.error('Error Enabling Wallet');
        setDRepInformationFetching(false);
      } else {
        if (
          (await walletFromKuber.networkId()) != wallet.network ||
          wallet.walletInfo === null
        ) {
          await dRepLogout().unwrap();
          dispatch(removeWallet());
          setDRepInformationFetching(false);
        }
        const dRepId = await getDRepIdFromCip30Wallet(walletFromKuber);
        if (dRepId) {
          try {
            const dRepInformation: DRepMetadata =
              await getDRepInformationById(dRepId).unwrap();
            const dRep = dRepInformation.dRep;
            if (dRepInformation.token && dRepInformation.token.length !== 0) {
              setTokenList(
                dRepInformation.token.map((dRepToken) => dRepToken.name)
              );
            }
            if (dRep && dRep.url && dRep.dataHash) {
              const metadata = await getMetadataResponse({
                url: dRep.url,
                hash: dRep.dataHash,
                isServersideFetch: false,
              });
              setMetadata(metadata);
            }
          } catch (e) {
            console.log('Failed to fetch the dRep information', e);
          } finally {
            setDRepInformationFetching(false);
          }
        } else {
          await dRepLogout().unwrap();
          dispatch(removeWallet());
          setDRepInformationFetching(false);
        }
      }
    }
  };

  const horizontalDivider = () => (
    <div className="h-[1px] w-full bg-layout-divider sm:hidden"></div>
  );

  const getNavBarContent = () => {
    if (isMounted && path === '/') {
      return (
        <>
          <div className="hidden h-5 w-[1px] rounded-[30px] bg-navbar-section-gradent sm:flex"></div>

          <div className="flex w-full flex-col items-center gap-2 sm:flex-row md:gap-10">
            {horizontalDivider()}
            <p
              className="min-w-[88px] cursor-pointer py-5 sm:p-0"
              onClick={() => handleNavigation(howItWorksRef)}
            >
              How it works
            </p>
            {horizontalDivider()}
            <p
              className="cursor-pointer py-5 sm:p-0"
              onClick={() => handleNavigation(featuresRef)}
            >
              Features
            </p>
            {/* <ModeChanger className="sm:hidden flex" /> */}
          </div>
        </>
      );
    }
  };

  const getButtonContent = () => {
    if (isMounted) {
      if (wallet.instance) {
        return (
          <ProfileDropDown
            label={wallet.name}
            profileImage={getImageUrlString(metadata?.body?.image)}
            tokenList={tokenList}
            isTokenFetching={isDRepInformationFetching}
            givenName={getFieldValue(metadata?.body?.givenName)}
          />
        );
      }
      return (
        <Button
          className="min-w-[130px]"
          onClick={() => openModal('CONNECT_WALLET')}
        >
          Connect Wallet
        </Button>
      );
    }
    return null;
  };

  return (
    <nav className="relative top-0 !z-30 flex h-[84px] w-full flex-col items-center justify-center bg-white text-neutral-900 dark:bg-neutral-950 dark:text-white">
      <div className="flex w-full max-w-[1440px] items-center justify-between gap-2 px-4 sm:px-5 md:gap-10 md:px-[72px]">
        <div className="body16 flex w-full items-center gap-2 font-medium sm:w-fit md:gap-4">
          {isMounted && path === '/' && (
            <div className="flex items-center gap-2 sm:hidden">
              <Burger
                className="h-6 w-6 cursor-pointer text-dark-neutral-800 dark:text-white"
                onClick={() => {
                  setMenuVisible(!isMenuVisible);
                }}
              />
              <div className="h-5 w-[1px] rounded-[30px] bg-navbar-section-gradent"></div>
            </div>
          )}

          <AnchorLink href="/" aria-label="navigate to landing page">
            <LogoComponent className="h-8 w-fit" />
          </AnchorLink>
          <div className="hidden items-center gap-2 sm:flex md:gap-4">
            {getNavBarContent()}
          </div>
        </div>
        <div className="text-text-primary flex w-full items-center justify-end gap-2">
          <div className="hidden sm:flex">
            <DRepSearch />
          </div>
          <Button
            className="flex sm:hidden"
            variant="neutral"
            onClick={() => openModal('SEARCH')}
          >
            <SearchIcon className="h-5 w-5" />
          </Button>
          {getButtonContent()}
          {/* <ModeChanger
            className={cn(isMounted && path === "/" && "sm:flex hidden")}
          /> */}
        </div>
      </div>
      {/* <!-- mobile menu --> */}
      {isMenuVisible && (
        <div className="body18 absolute top-[84px] flex min-h-calc-84 w-full flex-col items-center gap-8 bg-white px-5 font-medium dark:bg-dark-neutral-950 sm:hidden">
          {getNavBarContent()}
        </div>
      )}
    </nav>
  );
}

export default withWalletEventListener(Navbar);

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environments from '@src/configs/environments';
import type { IMetadata } from '@src/models/dtos/metadata';
import type {
  uploadImageRequest,
  uploadMetadataRequest,
  uploadMetadataResponse,
} from './types';

export const METADATA_PATH = 'metadata';
const METADATA_TAG = 'MetadataTag';
export const metadataApi = createApi({
  reducerPath: METADATA_PATH,
  tagTypes: [METADATA_TAG],
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: 'include',
    prepareHeaders: (headers, { endpoint }) => {
      if (endpoint !== 'uploadImage') {
        headers.append('Content-Type', 'application/json');
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMetadata: builder.query<IMetadata, string>({
      query: (dRepName) => ({
        url: `/metadata/${dRepName}`,
        method: 'GET',
      }),
      providesTags: [METADATA_TAG],
    }),
    uploadMetadata: builder.mutation<
      uploadMetadataResponse,
      uploadMetadataRequest
    >({
      query: ({ fileName, requestBody }) => ({
        url: `/metadata?filename=${fileName}`,
        method: 'POST',
        body: JSON.stringify(requestBody, null, 2),
      }),
      invalidatesTags: [METADATA_TAG],
    }),
    uploadVoteMetadata: builder.mutation<
      uploadMetadataResponse,
      uploadMetadataRequest
    >({
      query: ({ requestBody, folderName }) => ({
        url: `/metadata/vote${folderName ? `?folder=${folderName}` : ''}`,
        method: 'POST',
        body: JSON.stringify(requestBody, null, 2),
      }),
      invalidatesTags: [METADATA_TAG],
    }),
    uploadImage: builder.mutation<uploadMetadataResponse, uploadImageRequest>({
      query: ({ dRepName, imageFile }) => {
        const body = new FormData();
        body.append('file', imageFile);
        return {
          url: `/upload-image?filename=${dRepName}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [METADATA_TAG],
    }),
  }),
});

export const {
  useGetMetadataQuery,
  useUploadMetadataMutation,
  useUploadVoteMetadataMutation,
  useLazyGetMetadataQuery,
  useUploadImageMutation,
} = metadataApi;

import type { TextareaHTMLAttributes } from 'react';
import { useState } from 'react';
import cn from 'classnames';

type variant = 'error' | 'warning' | 'success' | 'default';

interface TextFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  variant?: variant;
  label?: string;
  helperText?: string;
}

const TEXTFIELDVARIANTS: Record<variant, string> = {
  error: 'border-semantic-error-500 focus:shadow-Error-Shadow-1',
  warning: 'border-semantic-warning-500 focus:shadow-Warning-Shadow-1',
  success: 'border-semantic-success-500 focus:shadow-Success-Shadow-1',
  default:
    'border-gray-200 disabled:bg-gray-300 disable:text-gray-400 hover:dark:border-dark-neutral-50 hover:shadow-Focus-Shadow-1 hover:dark:shadow-Inner-B-Shadow-6   dark:border-dark-neutral-700 focus:shadow-Default-Shadow-1 focus:dark:shadow-White-Shadow-1 focus:border-brand focus:dark:border-white dark:disabled:hover:border-transparent dark:disabled:hover:shadow-none  disabled:hover:shadow-none ',
};

const HELPERTEXTCLASS: Record<variant, string> = {
  error: 'text-semantic-error-500',
  warning: 'text-semantic-warning-500',
  success: 'text-semantic-success-500',
  default: 'text-gray-500',
};

export default function TextField({
  className,
  label,
  onChange,
  variant = 'default',
  maxLength = 1000,
  helperText,
  ...props
}: TextFieldProps) {
  const [currentTextLength, setCurrentTextLength] = useState(0);
  return (
    <label className="body14 relative flex flex-col gap-3 text-gray-900 disabled:bg-gray-100 disabled:text-gray-400 dark:text-white disabled:dark:bg-dark-neutral-600 disabled:dark:text-dark-neutral-100">
      {label && <span className="font-medium">{label}</span>}
      <div className="relative">
        <textarea
          maxLength={maxLength}
          {...props}
          onChange={(e) => {
            setCurrentTextLength(e.target.value.length);
            onChange?.(e);
          }}
          className={cn(
            'h-[108px] w-full resize-none rounded-[12px] border bg-white p-5 font-medium placeholder:text-gray-600 focus:outline-0 disabled:placeholder:text-gray-400 dark:bg-dark-neutral-600 placeholder:dark:text-gray-400 disabled:placeholder:dark:text-dark-neutral-100',
            className,
            TEXTFIELDVARIANTS[variant]
          )}
        />
        <p className="absolute bottom-[20px] right-[20px] z-10">
          {currentTextLength}/{maxLength}
        </p>
      </div>
      {helperText && (
        <span className={cn('body14 font-medium', HELPERTEXTCLASS[variant])}>
          {helperText}
        </span>
      )}
    </label>
  );
}

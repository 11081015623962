import Button from '@src/components/atoms/buttons/button';
import TextField from '@src/components/atoms/textfield/textField';
import { useState } from 'react';
import { useModal } from '@src/components/modal-views/context';
import cn from 'classnames';
import { Check } from '@src/components/icons/check';
import { vote } from '@src/lib/kuber-service';
import { useAppSelector } from '@src/store/hooks';
import { getCip30Wallet } from '@src/utils/kuberUtils';
import { blake2bHash } from '@src/utils/stringUtils';
import { useDispatch } from 'react-redux';
import { setPendingTransactions } from '@src/store/transaction/transaction';
import { getDRepIdFromCip30Wallet } from '@src/utils/dRepUtils';
import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import { isEmpty } from 'lodash';
import { useUploadVoteMetadataMutation } from '@src/store/metadata/api';
import {
  calculateFileHashFromValue,
  generateVoteContextFormat,
} from '@src/utils/metadataUtils';
import type { IAnchor } from '@src/models/dtos/metadata';

export default function CastVote({
  votedType,
  proposalId,
}: {
  votedType?: string;
  proposalId: string;
}) {
  const [context, setContext] = useState<string>('');
  const { closeModal } = useModal();
  const [isVoting, setIsVoting] = useState<boolean>(false);
  const wallet = useAppSelector((state) => state.wallet);
  const [currentSelectedVoteType, setCurrentSelectedVoteType] =
    useState<string>(votedType || '');
  const dispatch = useDispatch();
  const pendingTransactions = useAppSelector(
    (state) => state.pendingTransactions
  );
  const [uploadVoteMetadata] = useUploadVoteMetadataMutation();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContext(e.target.value);
  };

  const horizontalDivider = () => (
    <div className="h-[1px] w-full bg-layout-divider"></div>
  );

  const handleOnSelect = (voteType: string) => {
    setCurrentSelectedVoteType(voteType);
  };

  const uploadMetadataWithHash = async (
    comment: string,
    fileName: string
  ): Promise<IAnchor | undefined> => {
    const jsonMetadata = generateVoteContextFormat(comment);
    console.log(fileName);
    const response = await uploadVoteMetadata({
      folderName: 'context',
      requestBody: {
        commitMessage: `Upload file ${fileName}`,
        data: jsonMetadata,
      },
    }).unwrap();
    return {
      url: response.url,
      dataHash: calculateFileHashFromValue(jsonMetadata) || '',
    };
  };

  const handleVote = async () => {
    setIsVoting(true);
    if (!wallet.instance) {
      setIsVoting(false);
      return;
    }

    try {
      const kuberWallet = await getCip30Wallet(wallet);
      if (!kuberWallet) {
        setIsVoting(false);
        return;
      }

      const dRepId = await getDRepIdFromCip30Wallet(kuberWallet);
      const dRepPubKey = (await kuberWallet.cip95?.getPubDRepKey()) || '';
      const dRepPubKeyHash = blake2bHash(dRepPubKey);
      let anchor: IAnchor | undefined = undefined;
      const timeStamp = Date.now().toString();

      if (!isEmpty(context)) {
        const metadataResponse = await uploadMetadataWithHash(
          context,
          timeStamp
        );
        anchor = metadataResponse;
      }

      const voteResponse = await vote({
        wallet: kuberWallet,
        voteType: currentSelectedVoteType.toLowerCase(),
        proposal: proposalId,
        voter: dRepPubKeyHash,
        anchor,
      });

      const signTx = await kuberWallet.getSignedTx(voteResponse.cborHex);
      const txId = await kuberWallet.submitTx(signTx);

      if (txId) {
        dispatch(
          setPendingTransactions({
            ...pendingTransactions,
            pendingVotingTransaction: {
              txId: txId as string,
              name: proposalId + dRepId,
              time: Date.now(),
            },
          })
        );
        closeModal();
        toast.success('vote submitted', { toastId: ToastId.SUCCESS_TOAST });
      }
    } catch (e) {
      console.error(`Failed to vote due to ${e}`);
      toast.error(`Failed to vote due to ${e}`, {
        toastId: ToastId.ERROR_TOAST,
      });
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div className="mx-2 flex max-w-[569px] rounded-3xl border p-2 dark:border-dark-neutral-200">
      <div className="relative flex w-full flex-col items-center gap-4 rounded-[18px] bg-white p-6 shadow-modal-shadow dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <p className="body18 font-bold dark:text-white">
          Choose how you want to vote
        </p>
        <div className="flex w-full flex-col gap-4 rounded-xl border border-dark-neutral-700 bg-dark-neutral-800 p-4 sm:flex-row">
          <Button
            onClick={() => handleOnSelect('Yes')}
            variant="gray"
            disabled={isVoting}
            className={cn(
              'w-full items-center justify-center',
              currentSelectedVoteType === 'Yes'
                ? '!border-0 bg-semantic-success-500 !text-white hover:bg-semantic-success-600 focus:!ring-0 focus:!ring-offset-0'
                : 'bg-semantic-success-100 !text-semantic-success-500 hover:bg-semantic-success-300'
            )}
          >
            <span>Yes</span>
            {currentSelectedVoteType === 'Yes' && (
              <Check className="h-3 w-3 text-white" />
            )}
          </Button>
          <Button
            variant="gray"
            disabled={isVoting}
            onClick={() => handleOnSelect('No')}
            className={cn(
              'w-full justify-center',
              currentSelectedVoteType === 'No'
                ? '!border-0 bg-semantic-error-500 !text-white hover:bg-semantic-error-600 focus:!ring-0 focus:!ring-offset-0'
                : 'bg-semantic-error-100 !text-semantic-error-500 hover:bg-semantic-error-300'
            )}
          >
            <span> No</span>
            {currentSelectedVoteType === 'No' && (
              <Check className="h-3 w-3 text-white" />
            )}
          </Button>
          <Button
            onClick={() => handleOnSelect('Abstain')}
            disabled={isVoting}
            className={cn(
              'w-full justify-center',
              currentSelectedVoteType === 'Abstain' &&
                'border focus:!ring-0 focus:!ring-offset-0 dark:border-white dark:bg-dark-neutral-700 dark:text-white'
            )}
            variant="gray"
          >
            <span> Abstain</span>
            {currentSelectedVoteType === 'Abstain' && (
              <Check className="h-3 w-3 text-white" />
            )}
          </Button>
        </div>
        <div className="flex w-full flex-col gap-4 rounded-xl border border-dark-neutral-700 bg-dark-neutral-800 px-4 py-5">
          <p className="body14 text-center font-semibold dark:text-white">
            Optional
          </p>
          {horizontalDivider()}
          <p className="body14 text-center text-gray-400">
            You can provide context about your vote. This information will be
            viewable by other users.
          </p>
          <TextField
            id="context"
            onChange={handleOnChange}
            value={context}
            placeholder="eg. Context"
            className="h-[128px]"
          />
          <div className="flex w-full gap-3">
            <Button
              className="w-full justify-center"
              onClick={closeModal}
              variant="gray"
            >
              Cancel
            </Button>
            <Button
              disabled={isVoting || isEmpty(currentSelectedVoteType)}
              onClick={handleVote}
              isLoading={isVoting}
              className="w-full justify-center"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import cn from 'classnames';
import EmptySearchIllustration from '../icons/emptySearchIllustration';

export default function EmptySearchCard({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        'flex h-[147px] flex-col items-center justify-center gap-4 rounded-lg px-4 text-center dark:bg-dark-neutral-800',
        className
      )}
    >
      <EmptySearchIllustration />
      <p className="label12 dark:text-white">
        No DRep found matching your search. Please try another name or DRep ID.
      </p>
    </div>
  );
}

import type { SVGAttributes } from 'react';
import React from 'react';
import SocialShareWrapper from '@src/components/atoms/socialShareWrapper';
interface SocialIconWrapperProps {
  label: string;
  icon: (props: SVGAttributes<{}>) => React.JSX.Element;
}
export default function SocialIconWrapper({
  label,
  icon,
}: SocialIconWrapperProps) {
  return (
    <div className="flex cursor-pointer flex-col items-center gap-2">
      <div className="flex h-12 w-12 items-center justify-center rounded-full border p-[6px] dark:border-dark-neutral-700 dark:bg-dark-neutral-500">
        <SocialShareWrapper label={label}>
          {React.createElement(icon, {
            className: 'dark:text-white text-dark-neutral-100',
          })}
        </SocialShareWrapper>
      </div>
      <p className="body14 font-medium dark:text-white">{label}</p>
    </div>
  );
}

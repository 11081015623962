import React from 'react';

export default function CrossAvatar(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_di_4339_32933)">
        <path
          d="M13 13.3262C15.7614 13.3262 18 11.0876 18 8.32617C18 5.56475 15.7614 3.32617 13 3.32617C10.2386 3.32617 8 5.56475 8 8.32617C8 11.0876 10.2386 13.3262 13 13.3262Z"
          fill="url(#paint0_linear_4339_32933)"
        />
      </g>
      <g filter="url(#filter1_d_4339_32933)">
        <path
          d="M13 13.3262C15.7614 13.3262 18 11.0876 18 8.32617C18 5.56475 15.7614 3.32617 13 3.32617C10.2386 3.32617 8 5.56475 8 8.32617C8 11.0876 10.2386 13.3262 13 13.3262Z"
          stroke="#A9A8AB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_d_4339_32933)">
        <path
          d="M5 21.3262C5 19.9343 5.36317 18.5664 6.05363 17.3578C6.74409 16.1492 7.73794 15.1417 8.93699 14.4347C9.38736 14.0655 10.8086 13.3269 12.8906 13.3269C14.2824 13.3079 15.6551 13.6523 16.873 14.3262"
          stroke="#A9A8AB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter3_d_4339_32933)">
        <path
          d="M20 17.3262L25 22.3262M25 17.3262L20 22.3262"
          stroke="#A9A8AB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_4339_32933"
          x="4"
          y="3.32617"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_32933"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_32933"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.560784 0 0 0 0 0.560784 0 0 0 0 0.560784 0 0 0 0.37 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_4339_32933"
          />
        </filter>
        <filter
          id="filter1_d_4339_32933"
          x="3"
          y="2.32617"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_32933"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_32933"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4339_32933"
          x="0"
          y="12.3262"
          width="21.873"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_32933"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_32933"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_4339_32933"
          x="15"
          y="16.3262"
          width="15"
          height="15"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4339_32933"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4339_32933"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4339_32933"
          x1="13"
          y1="3.32617"
          x2="13"
          y2="13.3262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F4E51" />
          <stop offset="1" stopColor="#232125" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import React from 'react';
import AutocompleteSearch from '@src/components/atoms/autoCompleteSearch';
import { useLazySearchQuery } from '@src/store/search/api';
import { useRouter } from 'next/navigation';
import SearchCard from '@src/components/cards/searchCard';
import type { SearchDRepMetadataResponse } from '@src/store/search/types';

const DRepSearch = () => {
  const [searchQuery] = useLazySearchQuery();
  const router = useRouter();

  const handleSearchQuery = async (term: string) => {
    const result = await searchQuery(term).unwrap();
    return result;
  };

  const handleItemClick = (item: SearchDRepMetadataResponse) => {
    router.push(`/drep/${item.meta.drepIdCip129}`);
  };

  return (
    <AutocompleteSearch
      searchQuery={handleSearchQuery}
      placeholder="Search DReps..."
      renderItem={(item, index) => <SearchCard key={index} item={item} />}
      onItemClick={handleItemClick}
    />
  );
};

export default DRepSearch;

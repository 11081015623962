import { Fragment } from 'react';
import type { MODAL_VIEW } from '@src/components/modal-views/context';
import { useModal } from '@src/components/modal-views/context';
import {
  Dialog,
  DialogBackdrop,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import DisconnectWalletModal from '@src/components/modal-views/modals/disconnectWallet';
import RetireDRepModal from '@src/components/modal-views/modals/retireDRep';
import ShareModal from '@src/components/modal-views/modals/share';
import SearchModal from '@src/components/modal-views/modals/searchModal';
import ConnectWalletModal from '@src/components/modal-views/modals/connectWallet';
import FeeInfoDialog from '@src/app/feeInfo/FeeInfoDialog';
import ReviewYourChanges from '@src/components/modal-views/modals/reviewYourChanges';
import CastVote from '@src/components/modal-views/modals/castVote';

function renderModalContent(view: MODAL_VIEW | string, modalProps: any) {
  switch (view) {
    case 'DISCONNECT_WALLET':
      return <DisconnectWalletModal {...modalProps} />;
    case 'RETIRE_DREP':
      return <RetireDRepModal {...modalProps} />;
    case 'SHARE':
      return <ShareModal />;
    case 'SEARCH':
      return <SearchModal />;
    case 'CONNECT_WALLET':
      return <ConnectWalletModal />;
    case 'FEE_INFO':
      return <FeeInfoDialog {...modalProps} />;
    case 'REVIEW_YOUR_CHANGES':
      return <ReviewYourChanges {...modalProps} />;
    case 'CAST_VOTE':
      return <CastVote {...modalProps}></CastVote>;
    default:
      return <></>;
  }
}

export default function ModalContainer() {
  const { view, isOpen, closeModal, modalProps } = useModal();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        onClose={closeModal}
        className="item relative z-[999] h-full w-screen overflow-y-auto overflow-x-hidden transition duration-300 ease-out"
      >
        <DialogBackdrop className="fixed inset-0 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-overlay-100 dark:bg-dark-neutral-600 dark:bg-opacity-60 dark:backdrop-blur-sm">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-105"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-105"
          >
            <div
              data-testid="modal-view"
              className="relative z-50 flex h-full min-h-screen w-full items-center justify-center text-left md:w-fit"
            >
              {view && renderModalContent(view, modalProps)}
            </div>
          </TransitionChild>
        </DialogBackdrop>
      </Dialog>
    </Transition>
  );
}

import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';
import { capitalize } from '@src/utils/stringUtils';

export interface WalletCardProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  name: string;
  logo: string;
  selected?: boolean;
}
export default function WalletCard({
  name,
  logo,
  selected,
  ...props
}: WalletCardProps) {
  return (
    <button
      {...props}
      className={cn(
        selected
          ? 'bg-gray-200 dark:bg-primary-700'
          : 'bg-gray-50 dark:bg-dark-neutral-900',
        'flex min-w-[125px] flex-col items-center gap-3 rounded-lg border border-white border-opacity-5 py-4 hover:bg-gray-200 hover:dark:bg-primary-600'
      )}
    >
      <img src={logo} className="h-9 w-9" alt={`${name} Icon`} />
      <span className="body16 font-semibold dark:text-white">
        {capitalize(name)}
      </span>
    </button>
  );
}

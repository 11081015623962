import Dropdown from '@src/components/atoms/dropdown/dropdown';
import { useModal } from '@src/components/modal-views/context';
import showToast from '@src/components/molecules/toast';
import { ToastId } from '@src/constants/toastId';
import { useDRepLogoutMutation } from '@src/store/drep/api';
import type { RootState } from '@src/store/store';
import { removeWallet } from '@src/store/user/wallet';
import cn from 'classnames';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import LucidAvatar from '@src/components/icons/lucidAvatar';
import { MenuItem } from '@headlessui/react';
import ProfileCard from '@src/components/cards/profileCard';
import Avatar from '@src/components/atoms/avatar';
import React from 'react';
import AddAvatarIcon from '@src/components/icons/addAvatar';
import { eventEmitter } from '@src/layouts/_layout';

interface ProfileDropDownProps {
  label: string;
  profileImage?: string;
  tokenList?: Array<string>;
  givenName?: string;
  isTokenFetching?: boolean;
}

export default function ProfileDropDown({
  label,
  profileImage,
  tokenList,
  givenName,
  isTokenFetching,
}: ProfileDropDownProps) {
  const { openModal, closeModal } = useModal();
  const [dRepLogout] = useDRepLogoutMutation();
  const dispatch = useDispatch();
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();
  const params = useParams();
  const isInProfilePage =
    params && ('dRepName' in params || 'dRepId' in params);
  const path = usePathname();
  const handleDisconnectWallet = async () => {
    if (wallet.instance !== null) {
      try {
        eventEmitter.dispatchEvent(new Event('walletDisconnect'));
        await dRepLogout().unwrap();
        dispatch(removeWallet());
        if (path === '/drep/mint') router.replace('/');
        closeModal();
      } catch (error) {
        console.error('Failed to logout:', error);
      }
    } else {
      showToast({
        description: 'wallet connection error',
        toastId: ToastId.ERROR_TOAST,
        type: 'error',
      });
    }
  };
  const getActiveProfileName = ({
    isProfileCard = false,
  }: {
    isProfileCard?: boolean;
  } = {}): string => {
    if (tokenList && tokenList.length > 0) {
      if (!isInProfilePage) return tokenList[0];
      const activeToken = tokenList.find((token) => params?.dRepName === token);
      return isProfileCard ? activeToken || '' : activeToken || tokenList[0];
    }
    return '';
  };

  const renderMyProfileContent = () => {
    if (isTokenFetching) {
      return (
        <MenuItem key="renderProfile">
          <div className="body16 flex animate-pulse items-center gap-2 bg-neutral-700 p-3 font-medium">
            <LucidAvatar className="text-dark-neutral-700 dark:text-white" />
            <p className="dark:text-white">My profile</p>
            <p className="text-gray-400">@Loading...</p>
          </div>
        </MenuItem>
      );
    } else if (tokenList && tokenList.length > 0)
      return (
        <MenuItem key="renderProfile">
          <div
            onClick={() => router.replace(`/${getActiveProfileName()}`)}
            className="body16 flex cursor-pointer items-center gap-2 p-3 font-medium hover:bg-gray-100 dark:hover:bg-dark-neutral-800"
          >
            <LucidAvatar className="text-dark-neutral-700 dark:text-white" />
            <p className="dark:text-white">My profile</p>
            <p className="text-gray-400">@{getActiveProfileName()}</p>
          </div>
        </MenuItem>
      );
  };

  const renderMintDRepContent = () => (
    <MenuItem key="renderMintDRep">
      <div
        className={cn(
          'flex cursor-pointer items-center gap-2 border-y p-3 hover:bg-gray-100 dark:border-dark-neutral-700 dark:hover:bg-dark-neutral-800'
        )}
        onClick={() => router.push('/drep/mint')}
      >
        <AddAvatarIcon className="text-dark-neutral-700 dark:text-white" />
        <p className="body16 overflow-hidden text-ellipsis whitespace-nowrap font-medium dark:text-white">
          Mint DRep ID
        </p>
      </div>
    </MenuItem>
  );

  const renderDisconnectWalletContent = () => (
    <MenuItem key="renderDisconnectWallet">
      <div
        className={cn(
          'flex cursor-pointer items-center gap-2 border-y p-3 hover:bg-gray-100 dark:border-dark-neutral-700 dark:hover:bg-dark-neutral-800'
        )}
        onClick={() =>
          openModal('DISCONNECT_WALLET', { handleDisconnectWallet })
        }
      >
        <img
          src={wallet.icon}
          alt="Connected wallet icon"
          className="h-[18px] w-[18px] object-cover"
        />
        <p className="body16 overflow-hidden text-ellipsis whitespace-nowrap font-medium dark:text-white">
          Disconnect Wallet
        </p>
      </div>
    </MenuItem>
  );

  const renderSwitchAccountContent = () => {
    if (isTokenFetching) {
      return (
        <MenuItem key="renderSwitchAccount">
          <div className="h-30 flex animate-pulse flex-col bg-neutral-700 px-4 py-3">
            <p className="label12 font-semibold dark:text-white">
              SWITCH ACCOUNT
            </p>
            <div className="mt-4 flex max-h-[50vh] flex-col gap-2 overflow-y-auto">
              <ProfileCard
                disabled
                givenName="Loading..."
                tokenName="Loading..."
              />
            </div>
          </div>
        </MenuItem>
      );
    } else if (tokenList && tokenList.length > 0)
      return (
        <MenuItem key="renderSwitchAccount">
          <div className="flex flex-col px-4 py-3">
            <p className="label12 font-semibold dark:text-white">
              SWITCH ACCOUNT
            </p>
            <div className="mt-4 flex max-h-[50vh] flex-col gap-2 overflow-y-auto">
              {tokenList.map((token) => (
                <ProfileCard
                  key={token}
                  givenName={givenName}
                  profileImage={profileImage}
                  tokenName={token}
                  isActiveProfile={
                    ('dRepName' in params || 'dRepId' in params) &&
                    getActiveProfileName({
                      isProfileCard: true,
                    }).toLowerCase() === token.toLowerCase()
                  }
                />
              ))}
            </div>
          </div>
        </MenuItem>
      );
  };

  const menuItems = [
    renderMyProfileContent(),
    renderMintDRepContent(),
    renderDisconnectWalletContent(),
    renderSwitchAccountContent(),
  ].filter((item) => React.isValidElement(item));

  return (
    <Dropdown
      menuItemClassName="min-w-[250px]"
      contentWidth={tokenList && tokenList.length != 0 ? 260 : 180}
      label={label}
      prefixIcon={<Avatar size={24} src={profileImage} />}
      menuItems={menuItems}
    />
  );
}

import CrossIcon from '@src/components/icons/cross';
import withWalletEventListener from '@src/walletEvent/walletStateUtils';
import { useModal } from '@src/components/modal-views/context';

interface FeeInfoDialogPropos {
  feeInfo: (number | bigint)[];
}

function FeeInfoDialog({ feeInfo }: FeeInfoDialogPropos) {
  const parseFeeToAdaString = (): string[] => {
    const feeInAdaString: string[] = [];
    feeInfo.forEach((fee) => {
      const feeBigInt = typeof fee === 'bigint' ? fee : BigInt(fee);
      const scaledFee = (feeBigInt * BigInt(100)) / BigInt(1_000_000);
      const roundedFee =
        scaledFee +
        ((feeBigInt * BigInt(100)) % BigInt(1_000_000) > 0
          ? BigInt(1)
          : BigInt(0));
      feeInAdaString.push(`₳ ${(Number(roundedFee) / 100).toFixed(2)}`);
    });
    return feeInAdaString;
  };

  const feeList = parseFeeToAdaString();

  const { closeModal } = useModal();

  return (
    <div className="mx-2 flex rounded-3xl border p-2 dark:border-dark-neutral-200 md:min-w-[466px]">
      <div className="relative flex max-w-[616px] flex-col items-center gap-5 rounded-[18px] bg-white p-8 shadow-modal-shadow dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <div className="absolute left-0 top-0 h-[107px] w-full bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <CrossIcon
          onClick={closeModal}
          height={24}
          width={24}
          className="absolute right-4 top-4 cursor-pointer font-bold"
        />
        <h2 className="h24 text-center font-bold">Fee Configuration</h2>
        <p className="body16 text-center">
          The fee structure is designed so that shorter names have a higher fee,
          while longer names incur a lower fee. It eventually stabilizes at{' '}
          <span className="text-primary-200">
            {' '}
            <b>{feeList[feeList.length - 1]}</b>
          </span>{' '}
          for DRep names with a length of {feeList.length} characters or more.
        </p>
        <div className="w-full overflow-hidden rounded-lg border border-gray-300 dark:border-gray-700">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left dark:bg-gray-800">
                <th className="px-4 py-2 text-gray-700 dark:text-gray-300">
                  Name Length
                </th>
                <th className="px-4 py-2 text-gray-700 dark:text-gray-300">
                  Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {feeList.map((fee, index) => (
                <tr
                  key={index}
                  className="border-t border-gray-200 dark:border-gray-700"
                >
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    {index + 1 === feeList.length
                      ? `${index + 1} or more characters`
                      : `${index + 1} character${index + 1 > 1 ? 's' : ''}`}
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    {fee}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default withWalletEventListener(FeeInfoDialog);

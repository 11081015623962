import { useModal } from '../context';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import type { RootState } from '@src/store/store';
import { getCip30Wallet } from '@src/utils/kuberUtils';
import { dRepDeRegistration } from '@src/lib/kuber-service';
import { blake2bHash } from '@src/utils/stringUtils';
import {
  selectPendingTransactions,
  setPendingTransactions,
} from '@src/store/transaction/transaction';
import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import DialogIconWrapper from '@src/components/atoms/dialogIconWrapper';
import CrossAvatar from '@src/components/icons/crossAvatar';
import Button from '@src/components/atoms/buttons/button';
import Info from '@src/components/icons/info';
import CustomizeToolTip from '@src/components/atoms/tooltip';
import { toolTipDescription } from '@src/constants';

export default function RetireDRepModal({ tokenName }: { tokenName: string }) {
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const wallet = useAppSelector((state: RootState) => state.wallet);
  const dispatch = useAppDispatch();
  const pendingTransactions = useAppSelector(selectPendingTransactions);

  const handleDRepDeRegister = async () => {
    setIsLoading(true);
    if (wallet && wallet.instance) {
      try {
        const kuberClientWallet = await getCip30Wallet(wallet);
        if (!kuberClientWallet) {
          console.error('Error Enabling Wallet');
        } else {
          const dRepPubKey =
            (await kuberClientWallet.instance.cip95?.getPubDRepKey()) || '';
          const stakeDelegationResponse = await dRepDeRegistration(
            kuberClientWallet,
            '22' + blake2bHash(dRepPubKey)
          );

          const signTx = await kuberClientWallet.getSignedTx(
            stakeDelegationResponse.cborHex
          );

          await kuberClientWallet.submitTx(signTx).then((txId) => {
            if (txId) {
              dispatch(
                setPendingTransactions({
                  ...pendingTransactions,
                  pendingDRepRetireTransaction: {
                    txId: txId as string,
                    name: tokenName,
                    time: Date.now(),
                  },
                })
              );
            }
          });
        }
      } catch (e: any) {
        toast.error(`Failed to de-register dRep due to ${e.message}`, {
          toastId: ToastId.ERROR_TOAST,
        });
      } finally {
        setIsLoading(false);
        closeModal();
      }
    }
  };
  return (
    <div className="flex rounded-3xl border p-2 dark:border-dark-neutral-200 md:min-w-[466px]">
      <div className="relative flex w-full flex-col items-center gap-5 rounded-[18px] bg-white px-5 py-8 shadow-modal-shadow dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <div className="absolute left-0 top-0 h-[107px] w-full bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <DialogIconWrapper>
          <CrossAvatar />
        </DialogIconWrapper>
        <div className="flex flex-col gap-3 text-center text-neutral-900 dark:text-white">
          <div className="flex items-center justify-center gap-2">
            <h3 className="h24 font-bold">Retire as a DRep?</h3>
            <Info
              data-tooltip-place="right-start"
              data-tooltip-id="retireDRep"
              className="h-5 w-5 dark:text-gray-400"
            />
          </div>
          <p className="body16 w-full max-w-[326px]">
            Are you sure you want to disconnect the wallet? You can always
            connect again.
          </p>
        </div>
        <div className="h-[1px] w-full bg-section-gradent"></div>
        <div className="body16 flex items-center justify-center gap-3 font-medium">
          <Button
            onClick={closeModal}
            className="h-[40px] rounded-xl bg-semantic-success-600 px-3 text-white dark:border-gray-200 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={handleDRepDeRegister}>
            Retire
          </Button>
        </div>
      </div>
      <CustomizeToolTip
        description={toolTipDescription.retireDRep}
        id="retireDRep"
      />
    </div>
  );
}

import Button from '@src/components/atoms/buttons/button';
import DialogIconWrapper from '@src/components/atoms/dialogIconWrapper';
import EmptyContentIllustration from '@src/components/icons/emptyContentIllustration';
import Wallet from '@src/components/icons/wallet';
import { useModal } from '@src/components/modal-views/context';
import type { CIP30ProviderProxy } from 'kuber-client';
import { CIP30Wallet } from 'kuber-client';
import {
  useDRepLoginMutation,
  useLazyGetDRepByIdQuery,
} from '@src/store/drep/api';
import { useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useDispatch } from 'react-redux';
import { getDRepIdFromCip30Wallet } from '@src/utils/dRepUtils';
import { bech32 } from 'bech32';
import type { DRepMetadata, ILoginRequestBody } from '@src/store/drep/types';
import type { IWalletInfo } from '@src/store/user/wallet';
import { setWallet } from '@src/store/user/wallet';
import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import WalletCard from '@src/components/cards/walletCard';
import WalletDropdown from '@src/components/molecules/walletDropdown';

export default function ConnectWalletModal() {
  const { closeModal } = useModal();
  const [currentSelectedProvider, setCurrentSelectedProvider] =
    useState<CIP30ProviderProxy | null>(null);
  const [connectingProvider, setConnectingProvider] = useState<boolean>(false);
  const [getDRepInformationById] = useLazyGetDRepByIdQuery();
  const [dRepLogin] = useDRepLoginMutation();
  const path = usePathname();
  const router = useRouter();
  const dispatch = useDispatch();

  const handleSelect = (provider: CIP30ProviderProxy) => {
    setCurrentSelectedProvider(provider);
  };

  const enableWallet = async () => {
    setConnectingProvider(true);

    if (currentSelectedProvider !== null) {
      let isExperimentalWallet = false;

      if (
        experimentalProviders
          .map((provider) => provider.name)
          .includes(currentSelectedProvider.name)
      ) {
        isExperimentalWallet = true;
      }

      setTimeout(async () => {
        try {
          const enabledWallet = await currentSelectedProvider.enable({
            extensions: [
              {
                cip: 95,
              },
            ],
          });

          const stakeAddress =
            (await enabledWallet.instance.getRewardAddresses())[0] || '';

          let signingAdddress = stakeAddress; // address to use for signing the data.

          let signingKey = '';
          const registeredKeys =
            (await enabledWallet.cip95?.getRegisteredPubStakeKeys()) || [];
          const unregisteredKeys =
            (await enabledWallet.cip95?.getUnregisteredPubStakeKeys()) || [];

          if (registeredKeys?.length != 0) {
            signingKey = registeredKeys[0];
          } else if (unregisteredKeys?.length != 0) {
            signingKey = unregisteredKeys[0];
          }

          let walletInfo: IWalletInfo = { stakeAddress, isExpiremental: true };
          let callFinally: Function = () => {};

          if (!isExperimentalWallet) {
            const drepIdBech32 = await getDRepIdFromCip30Wallet(enabledWallet);
            if (!drepIdBech32) {
              throw new Error();
            }
            const drepIdHex = Buffer.from(
              bech32.fromWords(bech32.decode(drepIdBech32 as string, 100).words)
            ).toString('hex');
            signingAdddress = drepIdHex.slice(-56);
            walletInfo = { drepIdBech32, stakeAddress, isExpiremental: false };
            signingKey = (await enabledWallet.cip95?.getPubDRepKey()) as string;
            callFinally = async () => {
              if (path === '/') {
                const dRepInformation: DRepMetadata =
                  await getDRepInformationById(drepIdBech32).unwrap();
                if (
                  dRepInformation.token &&
                  dRepInformation.token.length !== 0
                ) {
                  router.push(`/${dRepInformation.token[0].name}`);
                } else {
                  router.refresh();
                }
              }
            };
          }

          const today = new Date();
          const expirationDate = new Date(today);
          expirationDate.setDate(today.getDate() + 7);
          const isoDateString = expirationDate.toISOString().slice(0, 10);
          const messageUTF8 = `valid until ${isoDateString}`;

          const dRepLoginRequestBody: ILoginRequestBody = {
            signature: await enabledWallet.signData(
              signingAdddress,
              Buffer.from(messageUTF8, 'utf-8').toString('hex')
            ),
            key: signingKey,
          };

          const response = await dRepLogin(dRepLoginRequestBody);

          if ('data' in response && response.data) {
            const networkId = await enabledWallet.networkId();
            dispatch(
              setWallet({
                name: currentSelectedProvider.name,
                icon: currentSelectedProvider.icon,
                instance: enabledWallet.instance,
                network: networkId,
                walletInfo: walletInfo,
              })
            );
            callFinally();
          } else {
            throw new Error(`Failed to login`);
          }
        } catch (error) {
          toast.error('Error enabling wallet');

          // code 3 for user declining to sign data
          if (error && typeof error == 'object') {
            console.error('logging error', error);
            if ('code' in error && error?.code == 3) {
              toast.error(`User declined to sign data`);
            } else {
              toast.error(
                `This wallet does not support signing data using the DRep public key.`,
                {
                  toastId: ToastId.ERROR_TOAST,
                }
              );
            }
          }

          console.error('Error enabling wallet:', error);
        } finally {
          closeModal();
          setConnectingProvider(false);
        }
      }, 1000);
    }
  };
  const nonExperimentalProviders: CIP30ProviderProxy[] = [];
  const experimentalProviders: CIP30ProviderProxy[] = [];
  const unsupportedProviders: CIP30ProviderProxy[] = [];

  CIP30Wallet.listProviders().forEach((provider) => {
    if (
      provider.name.toLowerCase() == 'eternl' ||
      provider.name.toLowerCase().includes('yoroi')
    ) {
      nonExperimentalProviders.push(provider);
    } else if (provider.supportedExtensions?.some((ext) => ext.cip === 95)) {
      experimentalProviders.push(provider);
    } else {
      unsupportedProviders.push(provider);
    }
  });

  const allProviders = [
    ...nonExperimentalProviders,
    ...experimentalProviders,
    ...unsupportedProviders,
  ];
  return (
    <div className="mx-2 flex max-w-[616px] rounded-3xl border p-2 text-center dark:border-dark-neutral-200 lg:w-[616px]">
      <div className="relative flex w-full flex-col items-center gap-5 rounded-[18px] bg-white bg-opacity-25 bg-connect-wallet-bg px-5 py-8 shadow-modal-shadow backdrop-blur-lg dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <DialogIconWrapper>
          <Wallet className="h-6 w-6" />
        </DialogIconWrapper>
        <div className="flex flex-col gap-2">
          <p className="body20 font-semibold dark:text-white">
            Connect your Wallet
          </p>
          <p className="body14 dark:text-gray-400">
            Select your Cardano wallet of choice to connect
          </p>
        </div>
        {allProviders.length == 0 ? (
          <div className="flex w-full flex-col items-center justify-center gap-4 rounded-2xl px-8 py-8 dark:bg-dark-neutral-800 md:px-[46px]">
            <EmptyContentIllustration />
            <p className="body16 w-full max-w-[318px] font-medium dark:text-white md:max-w-[318px]">
              No compatible wallet available or user does not have any wallet
              installed on their browser.
            </p>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center gap-5">
            <div className="flex w-full flex-wrap justify-center gap-4">
              {nonExperimentalProviders.map((provider, index) => (
                <WalletCard
                  key={provider.name + index}
                  name={provider.name}
                  logo={provider.icon}
                  onClick={() => handleSelect(provider)}
                  onDoubleClick={async () => {
                    handleSelect(provider);
                    await enableWallet();
                  }}
                  disabled={connectingProvider}
                  selected={
                    currentSelectedProvider
                      ? currentSelectedProvider.name === provider.name
                      : false
                  }
                />
              ))}
            </div>
            {(experimentalProviders.length !== 0 ||
              unsupportedProviders.length !== 0) && (
              <div className="flex w-full flex-col rounded-[9px] border dark:border-dark-neutral-700 dark:bg-dark-neutral-900">
                {experimentalProviders.length !== 0 && (
                  <WalletDropdown
                    title="Experimental Wallets"
                    providers={experimentalProviders}
                    toolTipDescription="These wallets do not support full cip95 features"
                    handleSelect={handleSelect}
                    enableWallet={enableWallet}
                    currentSelectedProvider={currentSelectedProvider}
                    disabled={connectingProvider}
                  />
                )}
                {experimentalProviders.length !== 0 &&
                  unsupportedProviders.length !== 0 && (
                    <div className="h-[1px] w-full bg-dark-neutral-700"></div>
                  )}
                {unsupportedProviders.length !== 0 && (
                  <WalletDropdown
                    title="Unsupported Wallets"
                    providers={unsupportedProviders}
                    toolTipDescription="These wallets do not support any cip95 features"
                    currentSelectedProvider={currentSelectedProvider}
                    disabled
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="h-[1px] w-full bg-layout-divider"></div>
        <div className="flex justify-center gap-3">
          <Button
            onClick={closeModal}
            className="h-[40px] rounded-xl bg-semantic-success-600 px-3 text-white dark:border-gray-200 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            disabled={connectingProvider || currentSelectedProvider == null}
            onClick={enableWallet}
          >
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
}

import CrossIcon from '@src/components/icons/cross';
import { useModal } from '../context';
import LinkIcon from '@src/components/icons/link';
import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import DialogIconWrapper from '@src/components/atoms/dialogIconWrapper';
import ShareIcon from '@src/components/icons/share';
import CopyIcon from '@src/components/icons/copy';
import SocialIconWrapper from '@src/components/atoms/socialIconWrapper';
import { socialShareIcons } from '@src/constants';

export default function ShareModal() {
  const { closeModal } = useModal();

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
  };

  const horizontalDivider = () => (
    <div className="h-[1px] w-full bg-layout-divider"></div>
  );

  return (
    <div className="mx-5 flex w-full max-w-[466px] rounded-3xl border p-2 text-center dark:border-dark-neutral-200">
      <div className="relative flex w-full flex-col items-center gap-5 rounded-[18px] bg-white px-5 py-8 shadow-modal-shadow dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <div className="absolute left-0 top-0 h-[107px] w-full bg-wallet-background-pattern bg-cover bg-no-repeat"></div>
        <CrossIcon
          onClick={closeModal}
          className="absolute right-4 top-4 h-[18px] w-[18px] cursor-pointer dark:text-white"
        />
        <DialogIconWrapper>
          <ShareIcon className="h-6 w-6 text-[#A9A8AB]" />
        </DialogIconWrapper>
        <div className="flex flex-col gap-2 dark:text-white">
          <p className="h24 font-bold"> Share this profile?</p>
          <p className="body16 w-full max-w-[300px]">
            Share it to your teammates or you can share the direct link to
            others.
          </p>
        </div>
        {horizontalDivider()}
        <div className="grid grid-cols-4 gap-6">
          {socialShareIcons.map((icon, index) => (
            <SocialIconWrapper
              key={index}
              icon={icon.icon}
              label={icon.label}
            />
          ))}
        </div>
        {horizontalDivider()}
        <div className="flex h-[46px] w-full items-center justify-between gap-2 rounded-xl border p-2 dark:border-dark-neutral-700 dark:bg-dark-neutral-600">
          <div className="flex items-center gap-2">
            <LinkIcon className="h-[18px] w-[18px] text-dark-neutral-900 dark:text-white" />
            <div className="h-[14px] w-[1px] rounded-[30px] bg-black bg-opacity-20 dark:bg-white"></div>
            <p className="body14 max-w-[180px] truncate font-medium text-dark-neutral-700 dark:text-white sm:max-w-[290px]">
              {window.location.href}
            </p>
          </div>
          <div className="rounded-[6px] bg-gray-100 p-[6px] hover:bg-gray-200 dark:bg-dark-neutral-400 dark:hover:bg-dark-neutral-500">
            <CopyIcon
              onClick={handleCopy}
              className="h-[18px] w-[18px] cursor-pointer text-dark-neutral-700 dark:text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import Button from '@src/components/atoms/buttons/button';
import type {
  IAnchor,
  IDRepFormField,
  ReferenceWithId,
} from '@src/models/dtos/metadata';
import { useModal } from '@src/components/modal-views/context';
import { useEffect, useState } from 'react';
import {
  useUploadImageMutation,
  useUploadMetadataMutation,
} from '@src/store/metadata/api';
import {
  useDRepLoginMutation,
  useLazyGetDRepByNameQuery,
} from '@src/store/drep/api';
import { useParams, useRouter } from 'next/navigation';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import {
  resetDRepRegistrationPendingTransaction,
  selectPendingTransactions,
  setPendingTransactions,
} from '@src/store/transaction/transaction';
import type { RootState } from '@src/store/store';
import {
  calculateFileHashFromValue,
  convertFormFieldToDRepMetadataBody,
  generateMetadata,
} from '@src/utils/metadataUtils';
import { getCip30Wallet } from '@src/utils/kuberUtils';
import { toast } from 'react-toastify';
import { ToastId } from '@src/constants/toastId';
import { bech32 } from 'bech32';
import { getDRepIdFromCip30Wallet } from '@src/utils/dRepUtils';
import type { ILoginRequestBody } from '@src/store/drep/types';
import { blake2bHash } from '@src/utils/stringUtils';
import { dRepRegistration, updateDRep } from '@src/lib/kuber-service';
import TransactionInProgressBadge from '@src/components/atoms/badges/transactionInProgressBadge';
import { useLazyGetTransactionStatusQuery } from '@src/store/transaction/api';
import { capitalize } from 'lodash';
import CustomizeToolTip from '@src/components/atoms/tooltip';

export default function ReviewYourChanges({
  dRepFormData,
  changedValue,
  isEditDRep = true,
}: {
  dRepFormData: IDRepFormField;
  changedValue: Record<string, any>;
  isEditDRep?: boolean;
}) {
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadMetadata] = useUploadMetadataMutation();
  const [uploadImage] = useUploadImageMutation();
  const [dRepLogin] = useDRepLoginMutation();
  const params = useParams();
  const tokenName = params.dRepName as string;
  const pendingTransactions = useAppSelector(selectPendingTransactions);
  const dispatch = useAppDispatch();
  const wallet = useAppSelector((state: RootState) => state.wallet);
  const [fetchDRepInformation] = useLazyGetDRepByNameQuery();
  const [fetchTransactionStatus] = useLazyGetTransactionStatusQuery();
  const router = useRouter();
  useEffect(() => {
    const checkTransactionStatus = async () => {
      const { pendingDRepRegistrationTransaction } = pendingTransactions;

      if (pendingDRepRegistrationTransaction.txId) {
        const isExpired =
          pendingDRepRegistrationTransaction.time + 4 * 60 * 1000 < Date.now();

        if (isExpired) {
          dispatch(resetDRepRegistrationPendingTransaction());
        }

        try {
          const response = await fetchDRepInformation(
            pendingDRepRegistrationTransaction.name
          ).unwrap();
          const transactionResponse = await fetchTransactionStatus(
            pendingDRepRegistrationTransaction.txId
          ).unwrap();
          if (
            response.dRep &&
            response.dRep.isRegisteredAsDRep &&
            transactionResponse.isTransactionConfirmed
          ) {
            dispatch(resetDRepRegistrationPendingTransaction());
            router.replace(`/${tokenName}`);
            closeModal();
          }
        } catch (error) {
          console.error(
            'Failed to fetch dRep registration transaction status',
            error
          );
        }
      }
    };

    const intervalId = setInterval(checkTransactionStatus, 5000);
    return () => clearInterval(intervalId);
  }, [pendingTransactions.pendingDRepRegistrationTransaction]);

  const uploadMetadataAndGetUrlWithFileHash = async (
    dRepFormField: IDRepFormField
  ) => {
    try {
      const getImageUrl = async (image: File) => {
        const response = await uploadImage({
          dRepName: tokenName,
          imageFile: image,
        }).unwrap();
        return response.url;
      };

      const uploadMetadataWithHash = async (metadataBody: any) => {
        const jsonMetadata = generateMetadata(metadataBody);
        const response = await uploadMetadata({
          fileName: tokenName,
          requestBody: {
            commitMessage: `Upload file ${tokenName}`,
            data: jsonMetadata,
          },
        }).unwrap();
        return {
          url: response.url,
          dataHash: calculateFileHashFromValue(jsonMetadata),
        };
      };

      const handleLoginAndRetry = async () => {
        if (!wallet?.instance) {
          toast.error('Wallet is not connected.', {
            toastId: ToastId.ERROR_TOAST,
          });
          return null;
        }

        const kuberClientWallet = await getCip30Wallet(wallet);
        if (!kuberClientWallet) {
          console.error('Error enabling wallet.');
          return null;
        }

        const enabledWallet = kuberClientWallet.instance;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        const isoDateString = expirationDate.toISOString().slice(0, 10);
        const messageUTF8 = `valid until ${isoDateString}`;

        const drepIdBech32 = await getDRepIdFromCip30Wallet(kuberClientWallet);
        const drepIdHex = Buffer.from(
          bech32.fromWords(bech32.decode(drepIdBech32 as string, 100).words)
        ).toString('hex');

        const dRepLoginRequestBody: ILoginRequestBody =
          await enabledWallet.signData(
            drepIdHex.slice(-56),
            Buffer.from(messageUTF8, 'utf-8').toString('hex')
          );

        const response = await dRepLogin(dRepLoginRequestBody);
        if ('data' in response && response.data) {
          console.log(response.data);
        } else {
          toast.error('Login Failed. Please try again', {
            toastId: ToastId.ERROR_TOAST,
          });
          handleLoginAndRetry();
        }
        return true;
      };

      const handleMetadataUpload = async () => {
        let imageUrl;
        if (dRepFormField?.image instanceof File) {
          imageUrl = await getImageUrl(dRepFormField.image);
        } else if (typeof dRepFormField?.image == 'string') {
          imageUrl = dRepFormField?.image;
        } else if (
          typeof dRepFormField?.image === 'object' &&
          dRepFormField?.image?.['@type'] === 'ImageObject' &&
          typeof dRepFormField?.image?.['contentUrl'] === 'string' &&
          typeof dRepFormField?.image?.['sha256'] === 'string' &&
          Object.keys(dRepFormField?.image).length === 3
        ) {
          imageUrl = dRepFormField?.image?.['contentUrl'];
        }
        const metadataBody = await convertFormFieldToDRepMetadataBody(
          dRepFormField,
          dRepFormField.givenName,
          imageUrl
        );
        return await uploadMetadataWithHash(metadataBody);
      };

      try {
        return await handleMetadataUpload();
      } catch (e: any) {
        if (
          e?.data &&
          typeof e.data.data === 'string' &&
          (e.data.data.toLowerCase().includes('missing cookie') ||
            e.data.data.toLowerCase().includes('expired'))
        ) {
          if (await handleLoginAndRetry()) {
            return await handleMetadataUpload();
          }
        } else {
          toast.error(`Failed to upload metadata. ${e.data.error || ''}`, {
            toastId: ToastId.ERROR_TOAST,
          });
          closeModal();
          return null;
        }
      }
    } catch (error: any) {
      console.error(`${error.message || error.data || error}`);
      toast.error('An unexpected error occurred.', {
        toastId: ToastId.ERROR_TOAST,
      });
      closeModal();
      return null;
    }
  };

  const generateAnchor = async (dRepFormField: IDRepFormField) => {
    const value = await uploadMetadataAndGetUrlWithFileHash(dRepFormField);
    if (value && value.dataHash) {
      return { url: value.url, dataHash: value.dataHash } as IAnchor;
    }
    toast.error('Failed to generate anchor', { toastId: ToastId.ERROR_TOAST });
  };

  const updateOrRegisterDRep = async () => {
    if (isLoading) return;
    setIsLoading(true);
    if (wallet && wallet.instance) {
      try {
        const kuberClientWallet = await getCip30Wallet(wallet);
        if (!kuberClientWallet) {
          console.error('Error Enabling Wallet');
        } else {
          const dRepPubKey =
            (await kuberClientWallet.instance.cip95?.getPubDRepKey()) || '';
          const anchor = await generateAnchor(dRepFormData);
          if (!anchor) return;

          const dRepId = '22' + blake2bHash(dRepPubKey);
          const stakeDelegationResponse = isEditDRep
            ? await updateDRep(kuberClientWallet, dRepId, anchor)
            : await dRepRegistration(kuberClientWallet, dRepId, anchor);

          const signTx = await kuberClientWallet.getSignedTx(
            stakeDelegationResponse.cborHex
          );
          await kuberClientWallet.submitTx(signTx).then((txId) => {
            if (txId) {
              dispatch(
                setPendingTransactions({
                  ...pendingTransactions,
                  pendingDRepRegistrationTransaction: {
                    txId: txId as string,
                    name: tokenName,
                    time: Date.now(),
                  },
                })
              );
            }
          });
        }
      } catch (e: any) {
        console.log(e);
        toast.error(`Failed to register dRep due to ${e.message || e.data}`, {
          toastId: ToastId.ERROR_TOAST,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const horizontalDivider = () => (
    <div className="h-[1px] w-full bg-layout-divider"></div>
  );

  const getObjectKeyValue = (key: string, value: any) => {
    switch (key) {
      case 'image':
        return {
          value: value instanceof File ? URL.createObjectURL(value) : value,
          key,
        };
      case 'references':
        return {
          key: value.map((ref: ReferenceWithId) => ref.label),
          value: value.map((ref: ReferenceWithId) => ref.uri),
        };
      default:
        return { key, value };
    }
  };

  const KeyValueRow = ({
    label,
    value,
    keyName,
  }: {
    label: string;
    value: string;
    keyName: string;
  }) => {
    return (
      <div className="flex gap-3">
        <p className="h14 min-w-[130px] font-semibold dark:text-white">
          {capitalize(label)}
        </p>
        <p
          data-tooltip-id={keyName + value}
          data-tooltip-place="top-start"
          className="h14 truncate-3-lines max-w-[410px] break-all dark:text-white"
        >
          {value}
          {value?.toString().length > 175 && (
            <CustomizeToolTip id={keyName + value} description={value} />
          )}
        </p>
      </div>
    );
  };

  const ReferencesSection = ({
    label,
    value,
  }: {
    label: string;
    value: any;
  }) => {
    return (
      <div className="flex flex-col gap-3">
        {value.map((uri: string, index: number) => (
          <div key={`${label}-${index}`} className="flex gap-3">
            <p className="h14 min-w-[130px] font-semibold dark:text-white">
              {capitalize(label[index])}
            </p>
            <p className="h14 max-w-[410px] break-all dark:text-white">{uri}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="mx-2 flex min-w-[300px] max-w-[616px] rounded-3xl border p-2 dark:border-dark-neutral-200 xl:min-w-[400px]">
      <div className="relative flex w-full flex-col items-center gap-5 rounded-[18px] bg-white p-6 shadow-modal-shadow dark:border dark:border-dark-neutral-700 dark:bg-dark-neutral-950 dark:bg-opacity-70 dark:shadow-Drop-Shadow">
        <div className="flex w-full flex-col items-start gap-4 md:flex-row md:items-center md:justify-between">
          <p className="h24 font-bold dark:text-white">Review your changes</p>
          {pendingTransactions.pendingDRepRegistrationTransaction.name ===
            tokenName && <TransactionInProgressBadge />}
        </div>
        {horizontalDivider()}
        <div className="flex w-full flex-col gap-3">
          {Object.keys(changedValue).map((key) => {
            const { key: label, value } = getObjectKeyValue(
              key,
              changedValue[key]
            );

            if (key === 'references') {
              return (
                <ReferencesSection key={key} label={label} value={value} />
              );
            }

            return (
              <KeyValueRow
                key={key}
                label={label}
                value={value}
                keyName={key}
              />
            );
          })}
        </div>
        {pendingTransactions.pendingDRepRegistrationTransaction.name !==
          tokenName && (
          <>
            {horizontalDivider()}
            <div className="flex w-full justify-end gap-3">
              <Button
                disabled={isLoading}
                onClick={closeModal}
                className="h-[40px] rounded-xl bg-semantic-success-600 px-3 text-white dark:border-gray-200 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-200"
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                onClick={updateOrRegisterDRep}
              >
                {isLoading ? 'Saving...' : 'Save Changes'}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import AnchorLink from '@src/components/atoms/links/anchor-link';
import LogoComponent from '@src/components/atoms/logoComponent';
import { socialLinks } from '@src/constants';
import { useScrollContext } from '@src/context/scroll-context';
import { useParams, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

export default function Footer() {
  const { scrollToSection, howItWorksRef, featuresRef, faqRef } =
    useScrollContext();
  const router = useRouter();
  const params = useParams();
  const [currentYear, setCurrentYear] = useState('');

  const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
    if (params?.page !== undefined) {
      router.replace('/');
    }
    scrollToSection(ref);
  };

  const primaryLinks = [
    {
      label: 'Home',
      onClick: () => router.replace('/'),
    },
    {
      label: 'About us',
      onClick: () => {},
    },
    {
      label: 'Features',
      onClick: () => handleNavigation(featuresRef),
    },
    {
      label: 'How it Works',
      onClick: () => handleNavigation(howItWorksRef),
    },
  ];

  const secondaryLinks = [
    {
      label: 'Docs',
      onClick: () => router.push('/docs'),
    },
    {
      label: 'FAQs',
      onClick: () => handleNavigation(faqRef),
    },
  ];

  useEffect(() => {
    setCurrentYear(new Date().getFullYear().toString());
  }, []);

  return (
    <div className="flex w-full flex-col gap-16 px-5 py-20 max-md:gap-8 lg:px-[158px]">
      <div className="flex">
        {primaryLinks.map((primaryLink, index) => (
          <div
            key={index}
            className="body20 flex cursor-pointer gap-x-4 font-medium max-md:gap-x-2 max-md:text-[16px] max-md:leading-[30px] max-md:tracking-[-0.33px]"
          >
            <span onClick={primaryLink.onClick}>{primaryLink.label}</span>
            {index < primaryLinks.length - 1 && (
              <span className="mr-4 max-md:mr-2">/</span>
            )}
          </div>
        ))}
      </div>
      <div className="h-[1px] w-full bg-white opacity-20" />
      <div className="flex max-md:flex-col">
        <div className="flex justify-between max-md:flex-col max-md:gap-y-8">
          <div className="flex w-[318px] flex-col gap-12">
            <div className="flex flex-col gap-y-4">
              <p className="body20 font-semibold">Contact Us</p>
              <p className="body16 text-gray-400">support@drep.id</p>
            </div>
            <div className="flex flex-col gap-y-4">
              <p className="body20 font-semibold">Location</p>
              <p className="body16 text-gray-400">
                483920, Buddhanagar, <br></br> Kathmandu 22/2/5, Office 4
              </p>
            </div>
          </div>
          <div className="flex w-[318px] flex-col">
            <p className="body20 mb-6 font-semibold">Quick Links</p>
            <div className="body16 flex flex-col gap-y-4 font-medium text-gray-400">
              {secondaryLinks.map((secondaryLink, index) => (
                <p
                  key={index}
                  onClick={secondaryLink.onClick}
                  className="cursor-pointer"
                >
                  {secondaryLink.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-end gap-16 max-md:mt-8 max-md:items-start">
          <LogoComponent />
          <div className="flex flex-col items-end gap-6 max-md:items-start">
            <div className="h-[1px] w-[45px] bg-white" />
            <p className="text-end text-gray-400 max-md:text-start">
              DRep ID is a decentralized platform helping <br /> users create
              unique, URL-friendly identities for <br /> governance and
              decentralized voting.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex gap-2">
          {socialLinks.map((socialLink, index) => (
            <AnchorLink
              href={socialLink.href}
              className="group flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-dark-neutral-800"
              key={index}
              target="new"
            >
              <socialLink.icon className="transition duration-500 group-hover:text-primary-700" />
            </AnchorLink>
          ))}
        </div>
        <div className="h-[1px] w-full bg-gray-200 opacity-10" />
      </div>
      <div className="flex justify-between max-md:flex-col max-md:gap-y-6">
        <div className="body16 flex gap-6 font-medium text-gray-400">
          <AnchorLink
            //@ts-ignore`
            href={'/docs/privacy-policy'}
            target="new"
          >
            <p className="body16">Privacy Policy</p>
          </AnchorLink>
          <AnchorLink
            //@ts-ignore`
            href={'/docs/terms-of-service'}
            target="new"
          >
            <p className="body16">Terms and Conditions</p>
          </AnchorLink>
        </div>
        <p className="body16 font-medium text-gray-400">
          © {currentYear} Sireto Technology. All rights reserved.
        </p>
      </div>
    </div>
  );
}
